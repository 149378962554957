import Swiper, { Thumbs, Navigation } from 'swiper';
window.addEventListener("cssLoaded", function () {
  document.querySelectorAll('.swiper-container').forEach(item => {
    let options = JSON.parse(item.getAttribute('data-swiper'))
    var nav = null;
    options.modules = [Navigation];
    if(options.thumbs)
    {
      nav = new Swiper(options.thumbs.element, options.thumbs.swiper);
      options.thumbs = {swiper: nav};
      options.modules = [Thumbs, Navigation];
    }
    new Swiper(item, options);
    if(item.querySelectorAll('.swiper-button-pause').length > 0) {
      item.querySelector('.swiper-button-pause').addEventListener('click', function (element) {
        element.target.closest('.swiper-container').swiper.autoplay.stop();
        element.target.closest('.swiper-container').classList.add('pause');
      });
    }
    if(item.querySelectorAll('.swiper-button-play').length > 0) {
      item.querySelector('.swiper-button-play').addEventListener('click', function (element) {
        element.target.closest('.swiper-container').swiper.autoplay.start();
        element.target.closest('.swiper-container').classList.remove('pause');
      });
    }
  })
});
